import React from 'react'
import NavBar from '../NavBar/NavBar'

function Header() {
  return (
    <div>
      <NavBar/>
    </div>
  )
}

export default Header